<template>
    <FilterExpand
        :title="title"
        :count="items.length"
        :expanded-default="expandedDefault"
        :mobile-menu-open="mobileMenuOpen"
        :data-cy="'filter-expand-' + slugify(title)"
    >
        <ul>
            <li
                v-for="item in sortedItems"
                :key="item.value"
                class="cursor-pointer rounded p-2 hover:bg-brew hover:text-white"
                :class="item.isRefined ? 'text-grind' : 'text-grind'"
                @click="toggleItem(item)"
                :data-cy="'filter-checkbox-' + slugify(title) + '-' + slugify(item.value)"
            >
                <div class="flex w-full items-center">
                    <input
                        :key="item.value"
                        :name="getUniqueInputName(item.value)"
                        :checked="item.isRefined"
                        :aria-label="item.value"
                        type="checkbox"
                        class="cursor-pointer checked:bg-grind"
                    />
                    <div
                        class="cursor-pointer"
                        :class="{
                            'font-bold': item.isRefined,
                        }"
                    >
                        {{ item.label ?? item.value }}
                    </div>
                </div>
            </li>
        </ul>
    </FilterExpand>
</template>

<script setup lang="ts">
import FilterExpand from '~/components/FilterExpand.vue';
import { sendDataLayerFilterEvent } from '~/composables/useDataLayer';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    type: {
        type: String,
    },
    expandedDefault: {
        type: Boolean,
        default: false,
    },
    mobileMenuOpen: {
        type: Boolean,
        default: false,
    },
    items: {
        type: Array,
        default: () => [],
    },
});

const sortedItems = computed(() => {
    const items = [...props.items];

    if (props.title == 'Availability') {
        items.sort(sortAvailability);
        if (items.find((item) => item.value == 'Blank')) {
            items.find((item) => item.value == 'Blank').label = 'Stock / Blank';
        }
    } else if (props.title == 'Product Type') {
        items.sort(sortProductTypes);
    } else if (props.title == 'Capacity') {
        items.sort(sortCapacity);
    } else if (props.title == 'Minimum Order Quantity') {
        items.sort(sortMinimumOrderQuantity);
    }

    return items;
});

const availabilityOrder = [
    'Custom-printed',
    'Blank',
];

const sortAvailability = (a: any, b: any) => {
    if (availabilityOrder.indexOf(a.label) < availabilityOrder.indexOf(b.label)) {
        return -1;
    } else if (
        availabilityOrder.indexOf(a.label) > availabilityOrder.indexOf(b.label)
    ) {
        return 1;
    }
    return 0;
};

const productTypeOrder = [
    'Bags',
    'Pouches',
    'Tin Cans',
    'Paper Tubes',
    'Beverage Labels',
    'Brand Stickers',
    'Closures',
];

const sortProductTypes = (a: any, b: any) => {
    if (productTypeOrder.indexOf(a.label) < productTypeOrder.indexOf(b.label)) {
        return -1;
    } else if (
        productTypeOrder.indexOf(a.label) > productTypeOrder.indexOf(b.label)
    ) {
        return 1;
    }
    return 0;
};

const capacityOrder = [
    'Samples (2-6oz)',
    'Small fill (6-10oz)',
    'Standard fill (10-12 oz)',
    'Large Fill (12 oz - 5 lbs)',
];

const sortCapacity = (a: any, b: any) => {
    if (capacityOrder.indexOf(a.label) < capacityOrder.indexOf(b.label)) {
        return -1;
    } else if (
        capacityOrder.indexOf(a.label) > capacityOrder.indexOf(b.label)
    ) {
        return 1;
    }
    return 0;
};

const sortMinimumOrderQuantity = (a: any, b: any) => {
    return parseInt(a.label) - parseInt(b.label);
};

const emit = defineEmits(['toggle']);

const getUniqueInputName = (value: string) => {
    let id = `${props.title}-${value}`;
    if (props.mobileMenuOpen) {
        id += '-mobile';
    }
    return id;
};

const toggleItem = (item: any) => {
    emit('toggle', item.value);

    sendDataLayerFilterEvent(
        'filter_selection',
        props.type ?? 'Products',
        props.title,
        item.label ?? item.value
    );
}

//@click="refine(item.value)"
</script>
